import React, { useState, useEffect, useRef } from 'react';
import './tabsOriginal.css';
import { FaAngleRight } from "react-icons/fa6";
import icon1 from '../../assets/imgs-calculadora/cozinha-sala-icon-1.png';
import icon2 from '../../assets/imgs-calculadora/cozinha-sala-icon-2.png';
import icon3 from '../../assets/imgs-calculadora/cozinha-sala-icon-3.png';
import icon4 from '../../assets/imgs-calculadora/cozinha-sala-icon-4.png';
import icon5 from '../../assets/imgs-calculadora/cozinha-sala-icon-5.png';
import icon6 from '../../assets/imgs-calculadora/cozinha-sala-icon-6.png';
import icon7 from '../../assets/imgs-calculadora/cozinha-sala-icon-7.png';
import icon8 from '../../assets/imgs-calculadora/cozinha-sala-icon-8.png';
import icon9 from '../../assets/imgs-calculadora/cozinha-sala-icon-9.png';
import icon10 from '../../assets/imgs-calculadora/cozinha-sala-icon-10.png';
import icon11 from '../../assets/imgs-calculadora/cozinha-sala-icon-11.png';
import icon12 from '../../assets/imgs-calculadora/cozinha-sala-icon-12.png';
import icon13 from '../../assets/imgs-calculadora/cozinha-sala-icon-13.png';
import icon14 from '../../assets/imgs-calculadora/cozinha-sala-icon-14.png';
import icon15 from '../../assets/imgs-calculadora/cozinha-sala-icon-15.png';
import icon16 from '../../assets/imgs-calculadora/cozinha-sala-icon-16.png';
import icon17 from '../../assets/imgs-calculadora/cozinha-sala-icon-17.png';
import icon18 from '../../assets/imgs-calculadora/cozinha-sala-icon-18.png';
import icon19 from '../../assets/imgs-calculadora/escritorio-icon-1.png';
import icon20 from '../../assets/imgs-calculadora/escritorio-icon-2.png';
import icon21 from '../../assets/imgs-calculadora/escritorio-icon-3.png';
import icon22 from '../../assets/imgs-calculadora/escritorio-icon-4.png';
import icon23 from '../../assets/imgs-calculadora/escritorio-icon-5.png';
import icon24 from '../../assets/imgs-calculadora/escritorio-icon-6.png';
import icon25 from '../../assets/imgs-calculadora/escritorio-icon-7.png';
import icon26 from '../../assets/imgs-calculadora/escritorio-icon-8.png';
import icon27 from '../../assets/imgs-calculadora/escritorio-icon-9.png';
import icon28 from '../../assets/imgs-calculadora/escritorio-icon-10.png';
import icon29 from '../../assets/imgs-calculadora/escritorio-icon-11.png';
import icon30 from '../../assets/imgs-calculadora/jardim-icon-1.png';
import icon31 from '../../assets/imgs-calculadora/jardim-icon-2.png';
import icon32 from '../../assets/imgs-calculadora/jardim-icon-3.png';
import icon33 from '../../assets/imgs-calculadora/jardim-icon-4.png';
import icon34 from '../../assets/imgs-calculadora/jardim-icon-5.png';
import icon35 from '../../assets/imgs-calculadora/jardim-icon-6.png';
import icon36 from '../../assets/imgs-calculadora/jardim-icon-7.png';
import icon37 from '../../assets/imgs-calculadora/jardim-icon-8.png';
import icon38 from '../../assets/imgs-calculadora/lavanderia-icon-1.png';
import icon39 from '../../assets/imgs-calculadora/lavanderia-icon-2.png';
import icon40 from '../../assets/imgs-calculadora/lavanderia-icon-3.png';
import icon41 from '../../assets/imgs-calculadora/lavanderia-icon-4.png';
import icon42 from '../../assets/imgs-calculadora/lavanderia-icon-5.png';
import icon43 from '../../assets/imgs-calculadora/quarto-icon-1.png';
import icon44 from '../../assets/imgs-calculadora/quarto-icon-2.png';
import icon45 from '../../assets/imgs-calculadora/quarto-icon-3.png';
import icon46 from '../../assets/imgs-calculadora/quarto-icon-4.png';
import icon47 from '../../assets/imgs-calculadora/quarto-icon-5.png';
import icon48 from '../../assets/imgs-calculadora/quarto-icon-6.png';
import icon49 from '../../assets/imgs-calculadora/quarto-icon-7.png';
import icon50 from '../../assets/imgs-calculadora/quarto-icon-8.png';
import icon51 from '../../assets/imgs-calculadora/quarto-icon-9.png';
import icon52 from '../../assets/imgs-calculadora/quarto-icon-10.png';
import icon53 from '../../assets/imgs-calculadora/quarto-icon-11.png';
import icon54 from '../../assets/imgs-calculadora/quarto-icon-12.png';
import icon55 from '../../assets/imgs-calculadora/quarto-icon-13.png';
import icon56 from '../../assets/imgs-calculadora/quarto-crianca-icon-1.png';
import icon57 from '../../assets/imgs-calculadora/quarto-crianca-icon-2.png';
import icon58 from '../../assets/imgs-calculadora/quarto-crianca-icon-3.png';
import icon59 from '../../assets/imgs-calculadora/quarto-crianca-icon-4.png';
import icon60 from '../../assets/imgs-calculadora/quarto-crianca-icon-5.png';
import icon61 from '../../assets/imgs-calculadora/quarto-crianca-icon-6.png';
import icon62 from '../../assets/imgs-calculadora/quarto-crianca-icon-7.png';
import icon63 from '../../assets/imgs-calculadora/quarto-crianca-icon-8.png';
import icon64 from '../../assets/imgs-calculadora/quarto-crianca-icon-9.png';
import icon65 from '../../assets/imgs-calculadora/quarto-crianca-icon-10.png';
import icon66 from '../../assets/imgs-calculadora/quarto-crianca-icon-11.png';
import icon67 from '../../assets/imgs-calculadora/quarto-crianca-icon-12.png';
import icon68 from '../../assets/imgs-calculadora/quarto-crianca-icon-13.png';
import icon69 from '../../assets/imgs-calculadora/sala-icon-1.png';
import icon70 from '../../assets/imgs-calculadora/sala-icon-2.png';
import icon71 from '../../assets/imgs-calculadora/sala-icon-3.png';
import icon72 from '../../assets/imgs-calculadora/sala-icon-4.png';
import icon73 from '../../assets/imgs-calculadora/sala-icon-5.png';
import icon74 from '../../assets/imgs-calculadora/sala-icon-6.png';
import icon75 from '../../assets/imgs-calculadora/sala-icon-7.png';
import icon76 from '../../assets/imgs-calculadora/sala-icon-8.png';
import icon77 from '../../assets/imgs-calculadora/sala-icon-9.png';
import icon78 from '../../assets/imgs-calculadora/sala-icon-10.png';
import icon79 from '../../assets/imgs-calculadora/sala-icon-11.png';
import icon80 from '../../assets/imgs-calculadora/sala-icon-12.png';

import { RxBorderSolid } from "react-icons/rx";
import { HiPlusSmall } from "react-icons/hi2";
import emailjs from 'emailjs-com';
import { FaCircleCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoCube } from "react-icons/io5";
import InputMask from 'react-input-mask';
import { IoAlertCircle } from "react-icons/io5";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const SuccessOrcamentoPopup = ({ message, onClose, handleClose }) => {
    return (
        <div>
            <div className="modal-overlay" onClick={handleClose}></div>
            <div className='modal'>
                <section className='modal-main' style={{ position: 'relative', padding: '40px 40px' }}>
                    <FaCircleCheck size={50} color='var(--cor-principal)' />
                    <h2 style={{ marginTop: '20px', marginBottom: '0px', color: "#000" }}>Orçamento enviado!</h2>
                    <br />
                    <p>Seu orçamento foi enviado com sucesso, fique atento ao meio de contato informado
                        pois enviaremos a resposta em até 48 horas.</p>
                    <div className='modal-buttons' style={{ marginTop: '20px' }}>
                        <button className='btn-cancel-modal' style={{ backgroundColor: "#253A5C", borderRadius: '4px', color: '#fff', width: '50%' }} onClick={onClose}>Entendi</button>
                    </div>
                </section>
            </div>

        </div>
    );
};

const ErrorPopup = ({ message, onClose, handleClose }) => {
    return (
        <div>
            <div className="modal-overlay" onClick={handleClose}></div>

            <div className='modal'>
                <section className='modal-main'>
                    <IoAlertCircle size={50} color='var(--cor-principal)' />
                    <h4 style={{ marginTop: '10px', marginBottom: '10px' }} >Parece que alguns campos estão faltando.</h4>
                    <p style={{ marginTop: '10px', lineHeight: '1.5em' }}>É necessário preencher com números válidos os campos de altura, largura e profundidade.</p>

                    <div className='modal-buttons' style={{ marginTop: '20px' }}>
                        <button className='btn-cancel-modal' onClick={onClose}>Fechar</button>
                    </div>
                </section>
            </div>

        </div>
    );
};

const Tabs = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        if (windowWidth >= 766) {
            setActiveTab(1);
        } else {
            setActiveTab(0);
        }
    }, [windowWidth]);
    const [tabData, setTabData] = useState([
        [
            { id: 1, title: 'Planta', image: icon1, quantity: 0, value: 0.05 },
            { id: 2, title: 'Mesa', image: icon2, quantity: 0, value: 1.5 },
            { id: 3, title: 'Lixeira', image: icon3, quantity: 0, value: 0.03 },
            { id: 4, title: 'Lava-louças', image: icon4, quantity: 0, value: 0.5 },
            { id: 5, title: 'Geladeira M', image: icon5, quantity: 0, value: 0.8 },
            { id: 6, title: 'Geladeira G', image: icon6, quantity: 0, value: 1.2 },
            { id: 7, title: 'Freezer', image: icon7, quantity: 0, value: 1.0 },
            { id: 8, title: 'Microondas', image: icon8, quantity: 0, value: 0.1 },
            { id: 9, title: 'Forno Elétrico', image: icon9, quantity: 0, value: 0.15 },
            { id: 10, title: 'Fogão', image: icon10, quantity: 0, value: 0.6 },
            { id: 11, title: 'Cadeirinha', image: icon11, quantity: 0, value: 0.05 },
            { id: 12, title: 'Cadeira', image: icon12, quantity: 0, value: 0.3 },
            { id: 13, title: 'Buffet', image: icon13, quantity: 0, value: 1.2 },
            { id: 14, title: 'Banco de bar', image: icon14, quantity: 0, value: 0.15 },
            { id: 15, title: 'Armário', image: icon15, quantity: 0, value: 1.5 },
            { id: 16, title: 'Caixa P', image: icon16, quantity: 0, value: 0.03 },
            { id: 17, title: 'Caixa M', image: icon17, quantity: 0, value: 0.06 },
            { id: 18, title: 'Caixa G', image: icon18, quantity: 0, value: 0.1 },
        ],
        [
            { id: 19, title: 'Mesa', image: icon19, quantity: 0, value: 1.5 },
            { id: 20, title: 'Cadeira', image: icon20, quantity: 0, value: 0.3 },
            { id: 21, title: 'Estante', image: icon21, quantity: 0, value: 1.0 },
            { id: 22, title: 'Armário', image: icon22, quantity: 0, value: 1.5 },
            { id: 23, title: 'Gaveteiros', image: icon23, quantity: 0, value: 0.2 },
            { id: 24, title: 'Computador', image: icon24, quantity: 0, value: 0.05 },
            { id: 25, title: 'Monitor', image: icon25, quantity: 0, value: 0.03 },
            { id: 26, title: 'Luminária', image: icon26, quantity: 0, value: 0.02 },
            { id: 27, title: 'Sofá', image: icon27, quantity: 0, value: 1.0 },
            { id: 28, title: 'Quadros', image: icon28, quantity: 0, value: 0.02 },
            { id: 29, title: 'Cadeira Visita', image: icon29, quantity: 0, value: 0.3 },
        ],
        [
            { id: 30, title: 'Espreguiçadeiras', image: icon30, quantity: 0, value: 0.5 },
            { id: 31, title: 'Mesa', image: icon31, quantity: 0, value: 1.5 },
            { id: 32, title: 'Banco', image: icon32, quantity: 0, value: 0.5 },
            { id: 33, title: 'Redes', image: icon33, quantity: 0, value: 0.1 },
            { id: 34, title: 'Vaso', image: icon34, quantity: 0, value: 0.05 },
            { id: 35, title: 'Mesa de piquenique', image: icon35, quantity: 0, value: 1.2 },
            { id: 36, title: 'Guarda-sol', image: icon36, quantity: 0, value: 0.3 },
            { id: 37, title: 'Aparador de grama', image: icon37, quantity: 0, value: 0.1 },
        ],
        [
            { id: 38, title: 'Máquina de lavar', image: icon38, quantity: 0, value: 0.5 },
            { id: 39, title: 'Secadora', image: icon39, quantity: 0, value: 0.5 },
            { id: 40, title: 'Tábua de passar', image: icon40, quantity: 0, value: 0.1 },
            { id: 41, title: 'Armário', image: icon41, quantity: 0, value: 1.0 },
            { id: 42, title: 'Cesto de roupas', image: icon42, quantity: 0, value: 0.05 },
        ],
        [
            { id: 43, title: 'Cama solteiro', image: icon43, quantity: 0, value: 1.2 },
            { id: 44, title: 'Cama casal', image: icon44, quantity: 0, value: 1.6 },
            { id: 45, title: 'Cama Beliche', image: icon45, quantity: 0, value: 2.0 },
            { id: 46, title: 'Criado-mudo', image: icon46, quantity: 0, value: 0.1 },
            { id: 47, title: 'Guarda-roupa', image: icon47, quantity: 0, value: 2.0 },
            { id: 48, title: 'Cômoda', image: icon48, quantity: 0, value: 0.8 },
            { id: 49, title: 'Espelho', image: icon49, quantity: 0, value: 0.05 },
            { id: 50, title: 'Luminária', image: icon50, quantity: 0, value: 0.02 },
            { id: 51, title: 'Poltrona', image: icon51, quantity: 0, value: 0.5 },
            { id: 52, title: 'TV', image: icon52, quantity: 0, value: 0.1 },
            { id: 53, title: 'Baú', image: icon53, quantity: 0, value: 0.3 },
            { id: 54, title: 'Cortina', image: icon54, quantity: 0, value: 0.02 },
            { id: 55, title: 'Escrivaninha', image: icon55, quantity: 0, value: 1.0 },
        ],
        [
            { id: 56, title: 'Cama', image: icon56, quantity: 0, value: 1.2 },
            { id: 57, title: 'Berço', image: icon57, quantity: 0, value: 0.8 },
            { id: 58, title: 'Estante', image: icon58, quantity: 0, value: 1.0 },
            { id: 59, title: 'Mesa', image: icon59, quantity: 0, value: 0.5 },
            { id: 60, title: 'Guarda-roupa', image: icon60, quantity: 0, value: 1.5 },
            { id: 61, title: 'Cômoda', image: icon61, quantity: 0, value: 0.8 },
            { id: 62, title: 'Cadeira', image: icon62, quantity: 0, value: 0.3 },
            { id: 63, title: 'Luminária', image: icon63, quantity: 0, value: 0.02 },
            { id: 64, title: 'Poltrona', image: icon64, quantity: 0, value: 0.5 },
            { id: 65, title: 'TV', image: icon65, quantity: 0, value: 0.1 },
            { id: 66, title: 'Baú', image: icon66, quantity: 0, value: 0.3 },
            { id: 67, title: 'Cortina', image: icon67, quantity: 0, value: 0.02 },
            { id: 68, title: 'Escrivaninha', image: icon68, quantity: 0, value: 0.8 },
        ],
        [
            { id: 69, title: 'Sofá médio', image: icon69, quantity: 0, value: 1.5 },
            { id: 70, title: 'Sofá grande', image: icon70, quantity: 0, value: 2.0 },
            { id: 71, title: 'Poltrona', image: icon71, quantity: 0, value: 0.5 },
            { id: 72, title: 'Mesa de centro', image: icon72, quantity: 0, value: 0.6 },
            { id: 73, title: 'Estante', image: icon73, quantity: 0, value: 1.5 },
            { id: 74, title: 'Rack de TV', image: icon74, quantity: 0, value: 0.8 },
            { id: 75, title: 'TV', image: icon75, quantity: 0, value: 0.1 },
            { id: 76, title: 'Quadro', image: icon76, quantity: 0, value: 0.02 },
            { id: 77, title: 'Vaso', image: icon77, quantity: 0, value: 0.05 },
            { id: 78, title: 'Cortina', image: icon78, quantity: 0, value: 0.02 },
            { id: 79, title: 'Piano', image: icon79, quantity: 0, value: 1.5 },
            { id: 80, title: 'Bar', image: icon80, quantity: 0, value: 0.5 },
        ]
    ]);

    const getImageClass = (id) => {
        switch (id) {
            case 6:
                return { border: '2px solid red', borderRadius: '10px' };
        }
    };

    const [totalItems, setTotalItems] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [cartItems, setCartItems] = useState([]);

    const [altura, setAltura] = useState('');
    const [largura, setLargura] = useState('');
    const [profundidade, setProfundidade] = useState('');
    const [nomeItemPersonalizado, setNomeItemPersonalizado] = useState('');

    const handleAddCustomItem = () => {
        const alturaFloat = parseFloat(altura);
        const larguraFloat = parseFloat(largura);
        const profundidadeFloat = parseFloat(profundidade);

        if (!isNaN(alturaFloat) && !isNaN(larguraFloat) && !isNaN(profundidadeFloat)) {
            const metragem = alturaFloat * larguraFloat * profundidadeFloat;

            const newItem = {
                id: Date.now(),
                title: nomeItemPersonalizado || `Objeto Personalizado ${cartItems.length + 1}`, // Usar cartItems.length para garantir um título único
                image: <IoCube size={22} style={{ margin: '0 4px', color: 'var(--cor-principal)' }} />,
                quantity: 1,
                value: parseFloat(metragem.toFixed(2)), // Convertendo para número
            };

            // Adiciona ao carrinho de compras apenas
            setCartItems([...cartItems, newItem]);

            // Limpar os campos após adicionar o item
            setAltura('');
            setLargura('');
            setProfundidade('');
            setNomeItemPersonalizado('');
        } else {
            handleShowPopupError();
        }
    };

    const updateCart = (productId, change) => {
        let productToUpdate = null;
        let updatedTabs = [...tabData]; // Cópia das abas
        let updatedCart = [...cartItems]; // Cópia do carrinho

        // Verifica se o produto está em uma das abas
        let foundInTabs = false;
        updatedTabs.forEach((tab, tabIndex) => {
            const productIndex = tab.findIndex(product => product.id === productId);
            if (productIndex !== -1) {
                productToUpdate = { ...tab[productIndex] };
                foundInTabs = true;

                const itemIndex = updatedCart.findIndex(item => item.id === productId);
                if (itemIndex === -1 && change > 0) {
                    // Adiciona ao carrinho
                    updatedCart.push({
                        id: productToUpdate.id,
                        title: productToUpdate.title,
                        image: productToUpdate.image,
                        quantity: change,
                        value: parseFloat((productToUpdate.value * change).toFixed(2)),
                    });
                } else if (itemIndex !== -1) {
                    // Atualiza quantidade no carrinho
                    updatedCart[itemIndex].quantity += change;
                    updatedCart[itemIndex].value = parseFloat((productToUpdate.value * updatedCart[itemIndex].quantity).toFixed(2));

                    // Remove do carrinho se a quantidade for zero ou menos
                    if (updatedCart[itemIndex].quantity <= 0) {
                        updatedCart.splice(itemIndex, 1);
                    }
                }
            }
        });

        // Se não encontrou nas abas, trata como item personalizado (Tab 4)
        if (!foundInTabs && activeTab === 4) {
            productToUpdate = updatedCart.find(item => item.id === productId);

            if (productToUpdate) {
                const itemIndex = updatedCart.findIndex(item => item.id === productId);
                updatedCart[itemIndex].quantity += change;
                updatedCart[itemIndex].value = parseFloat((productToUpdate.value * updatedCart[itemIndex].quantity).toFixed(2));

                // Remove do carrinho se a quantidade for zero ou menos
                if (updatedCart[itemIndex].quantity <= 0) {
                    updatedCart.splice(itemIndex, 1);
                }
            }
        }

        // Atualiza os estados com os dados atualizados
        setCartItems(updatedCart);
        setTabData(updatedTabs);
    };

    const handleQuantityChange = (productId, change) => {
        let updatedTabs = [...tabData]; // Cópia das abas

        // Verifica se o produto está em uma das abas
        let foundInTabs = false;
        updatedTabs.forEach((tab, tabIndex) => {
            const productIndex = tab.findIndex(product => product.id === productId);
            if (productIndex !== -1) {
                foundInTabs = true;
                const updatedProduct = { ...updatedTabs[tabIndex][productIndex] };
                updatedProduct.quantity += change;
                updatedProduct.quantity = Math.max(updatedProduct.quantity, 0); // Garante que a quantidade não seja negativa
                updatedTabs[tabIndex][productIndex] = updatedProduct;

                // Atualiza o estado com os dados atualizados das abas
                setTabData([...updatedTabs]);

                // Atualiza o carrinho de compras
                updateCart(productId, change);
            }
        });

        // Se não encontrou nas abas e é item personalizado (Tab 4 ou fora da Tab 4), trata adequadamente
        if (!foundInTabs) {
            const itemIndex = cartItems.findIndex(item => item.id === productId);
            if (itemIndex !== -1) {
                const updatedCart = [...cartItems];
                updatedCart[itemIndex].quantity += change;
                updatedCart[itemIndex].value = parseFloat((updatedCart[itemIndex].value * updatedCart[itemIndex].quantity).toFixed(2));

                // Remove do carrinho se a quantidade for zero ou menos
                if (updatedCart[itemIndex].quantity <= 0) {
                    updatedCart.splice(itemIndex, 1);
                }

                // Atualiza o estado com os dados atualizados do carrinho
                setCartItems(updatedCart);
            }
        }
    };


    useEffect(() => {
        // Calcula o total de itens adicionados e o valor total
        let itemsCount = 0;
        let itemsValue = 0;

        cartItems.forEach((item) => {
            itemsCount += item.quantity;
            itemsValue += parseFloat(item.value); // Converter para número antes de somar
        });

        setTotalItems(itemsCount);
        setTotalValue(itemsValue.toFixed(2)); // Formatando para duas casas decimais
    }, [cartItems]);

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupError, setShowPopupError] = useState(false);

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setCartItems([]);
        setTelefone('');
    };

    const handleShowPopupError = () => {
        setShowPopupError(true);
    };

    const handleClosePopupError = () => {
        setShowPopupError(false);
    };

    const [telefone, setTelefone] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        // Construindo a lista de produtos selecionados como texto formatado
        const selectedProducts = cartItems.map(item => `${item.title} - Quantidade: ${item.quantity}, Total: ${item.value} m³`).join('\n');

        // Coletando os dados do formulário
        const formData = {
            name: event.target.name.value,
            tel: event.target.tel.value,
            email: event.target.email.value,
            items: totalItems,
            total: totalValue + ' m³',  // Adicionando unidade de medida
            selectedProducts: selectedProducts
        };

        // Enviando o formulário usando emailjs
        emailjs.send('service_gquzhes', 'template_r6gfo16', formData, 'DbNcohiwnEQhnc4eO')
            .then((result) => {
                handleShowPopup();
            }, (error) => {
                console.log(error.text);
                alert('Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente mais tarde.');
            });

        // Resetando o formulário após o envio
        event.target.reset();
    };

    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpen3, setDropdownOpen3] = useState(false);

    const toggleDropdown1 = () => {
        setDropdownOpen1(!dropdownOpen1);
    };

    const toggleDropdown2 = () => {
        setDropdownOpen2(!dropdownOpen2);
    };

    const toggleDropdown3 = () => {
        setDropdownOpen3(!dropdownOpen3);
    };

    const dropdown2Ref = useRef(null);
    const [scrollTrigger, setScrollTrigger] = useState(false);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        setDropdownOpen1(false);
        setDropdownOpen2(true);
        setDropdownOpen3(true);

    };

    const getTabLabel = () => {
        switch (activeTab) {
            case 1:
                return 'Cozinha/sala de jantar';
            case 2:
                return 'Escritório';
            case 3:
                return 'Jardim';
            case 4:
                return 'Lavanderia e limpeza';
            case 5:
                return 'Quarto';
            case 6:
                return 'Quarto de criança';
            case 7:
                return 'Sala de estar';
            case 8:
                return 'Objeto personalizado';
            default:
                return 'Selecionar uma opção';
        }
    };

    const tabOptions = [
        { id: 1, label: 'Cozinha/sala de jantar' },
        { id: 2, label: 'Escritório' },
        { id: 3, label: 'Jardim' },
        { id: 4, label: 'Lavanderia e limpeza' },
        { id: 5, label: 'Quarto' },
        { id: 6, label: 'Quarto de criança' },
        { id: 7, label: 'Sala de estar' },
        { id: 8, label: 'Objeto personalizado' }
    ];


    return (
        <div className='container-tabs' id='orcamento'>
            <h1>Precisa de um orçamento? <br />
                Selecione seus itens e solicite aqui</h1>
            <div className="tabs-orcamento">

                {window.innerWidth < 766 ? (
                    <div className="tab-buttons-orcamento">
                        <div className='content-title-orcamento' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }} onClick={toggleDropdown1}>
                            <div className='' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>1</span>
                                <h3>Escolha o Ambiente</h3>
                            </div>
                            {dropdownOpen1 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </div>

                        {dropdownOpen1 && (
                            <div className='content-buttons-orcamento'>
                                {tabOptions.map((tab) => (
                                    <button
                                        key={tab.id}
                                        className={activeTab === tab.id ? 'active' : ''}
                                        onClick={() => handleTabClick(tab.id)}
                                    >
                                        {tab.label}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="tab-buttons-orcamento">
                            <div className='content-title-orcamento'>
                                <span>1</span>
                                <h3>Escolha o Ambiente</h3>
                            </div>

                            <div className='content-buttons-orcamento'>
                                {tabOptions.map((tab) => (
                                    <button
                                        key={tab.id}
                                        className={activeTab === tab.id ? 'active' : ''}
                                        onClick={() => handleTabClick(tab.id)}
                                    >
                                        {tab.label}
                                        <FaAngleRight />
                                    </button>
                                ))}
                            </div>
                        </div>
                    </>
                )}


                {window.innerWidth < 766 ? (
                    <div className="tab-content-orcamento">
                        <div className='content-title-orcamento' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }} onClick={toggleDropdown2}>
                            <div className='' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>2</span>
                                <h3>Selecione os itens que deseja orçar</h3>
                            </div>
                            {dropdownOpen2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </div>
                        {dropdownOpen2 && (
                            <div className='content-produtcs'>
                                {activeTab !== 8 && (
                                    <div className="product-cards">
                                        {tabData[activeTab - 1] && tabData[activeTab - 1].map((product) => (
                                            <div key={product.id} className="product-card">
                                                <h3>{product.title}</h3>
                                                <div className='imagem-produto'>
                                                    <img src={product.image} alt={product.title} />
                                                </div>
                                                <div className="quantity-controls">
                                                    <button
                                                        onClick={() => handleQuantityChange(product.id, -1)}
                                                        disabled={product.quantity === 0}
                                                    >
                                                        <RxBorderSolid className='icon-quantity' />
                                                    </button>
                                                    <span>{product.quantity}</span>
                                                    <button onClick={() => handleQuantityChange(product.id, 1)}>
                                                        < HiPlusSmall className='icon-quantity' />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {activeTab === 8 && (
                                    <div className="custom-item-form">
                                        <h4>Objeto personalizado</h4>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                value={nomeItemPersonalizado}
                                                onChange={(e) => setNomeItemPersonalizado(e.target.value)}
                                                placeholder='Nome:'
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                value={altura}
                                                onChange={(e) => setAltura(e.target.value)}
                                                placeholder='Altura (m):'
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                value={largura}
                                                onChange={(e) => setLargura(e.target.value)}
                                                placeholder='Largura (m):'
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                value={profundidade}
                                                onChange={(e) => setProfundidade(e.target.value)}
                                                placeholder='Profundidade (m):'
                                            />
                                        </div>
                                        <button className='submit-button' onClick={handleAddCustomItem}>Adicionar Item</button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="tab-content-orcamento">
                        <div className='content-title-orcamento' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }} onClick={toggleDropdown3}>
                            <div className='' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>2</span>
                                <h3>Selecione os itens que deseja orçar</h3>
                            </div>
                        </div>

                        <div className='content-produtcs'>
                            {activeTab !== 8 && (
                                <div className="product-cards">
                                    {tabData[activeTab - 1] && tabData[activeTab - 1].map((product) => (
                                        <div key={product.id} className="product-card">
                                            <h3>{product.title}</h3>
                                            <div className='imagem-produto'>
                                                <img src={product.image} alt={product.title} />
                                            </div>
                                            <div className="quantity-controls">
                                                <button
                                                    onClick={() => handleQuantityChange(product.id, -1)}
                                                    disabled={product.quantity === 0}
                                                >
                                                    <RxBorderSolid className='icon-quantity' />
                                                </button>
                                                <span>{product.quantity}</span>
                                                <button onClick={() => handleQuantityChange(product.id, 1)}>
                                                    < HiPlusSmall className='icon-quantity' />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {activeTab === 8 && (
                                <div className="custom-item-form">
                                    <h4>Objeto personalizado</h4>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            value={nomeItemPersonalizado}
                                            onChange={(e) => setNomeItemPersonalizado(e.target.value)}
                                            placeholder='Nome:'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            value={altura}
                                            onChange={(e) => setAltura(e.target.value)}
                                            placeholder='Altura (m):'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            value={largura}
                                            onChange={(e) => setLargura(e.target.value)}
                                            placeholder='Largura (m):'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            value={profundidade}
                                            onChange={(e) => setProfundidade(e.target.value)}
                                            placeholder='Profundidade (m):'
                                        />
                                    </div>
                                    <button className='submit-button' onClick={handleAddCustomItem}>Adicionar Item</button>
                                </div>
                            )}
                        </div>

                    </div>
                )}

                {window.innerWidth < 766 ? (
                    <div className="cart-summary">
                        <div className='content-title-orcamento' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }} onClick={toggleDropdown3}>
                            <div className='' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>3</span>
                                <h3>Espaço Necessário</h3>
                            </div>
                            {dropdownOpen3 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </div>

                        {dropdownOpen3 && (
                            <>
                                <ul>
                                    {cartItems.map((item) => (
                                        <>
                                            <li key={item.id}>
                                                <div className='intro-cart-summary'>
                                                    {typeof item.image === 'string' ? (
                                                        <img src={item.image} alt={item.title} />
                                                    ) : (
                                                        item.image // Renderiza diretamente o ícone
                                                    )}
                                                    <strong>{item.title}</strong>
                                                </div>
                                                <div className="quantity-controls">
                                                    <button
                                                        onClick={() => handleQuantityChange(item.id, -1)}
                                                        disabled={item.quantity === 0}
                                                    >
                                                        <RxBorderSolid className='icon-quantity' />
                                                    </button>
                                                    <span>{item.quantity}</span>
                                                    <button onClick={() => handleQuantityChange(item.id, 1)}>
                                                        < HiPlusSmall className='icon-quantity' />
                                                    </button>
                                                </div>
                                            </li>
                                        </>
                                    ))}

                                    <div>
                                        <p>Itens: {totalItems}</p>
                                        <p>Total: {totalValue} m³</p>
                                    </div>
                                </ul>
                                <form className="contact-form" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input type="text" id="name" name="name" placeholder='Nome:' required />
                                    </div>

                                    <div className="form-group">
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            id="tel"
                                            type="tel"

                                            onChange={(e) => setTelefone(e.target.value)}
                                            placeholder='Telefone:'
                                            required
                                        />

                                    </div>

                                    <div className="form-group">
                                        <input type="email" id="email" name="email" placeholder='Email:' required />
                                    </div>
                                    <button type="submit" className="submit-button">Solicitar Orçamento</button>
                                </form>
                            </>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="cart-summary">
                            <div className='content-title-orcamento'>
                                <span>3</span>
                                <h3>Espaço Necessário</h3>
                            </div>
                            <ul>
                                {cartItems.map((item) => (
                                    <>
                                        <li key={item.id}>
                                            <div className='intro-cart-summary'>
                                                {typeof item.image === 'string' ? (
                                                    <div className='imagem-produto'>
                                                        <img src={item.image} alt={item.title} />
                                                    </div>
                                                ) : (
                                                    item.image // Renderiza diretamente o ícone
                                                )}
                                                <strong>{item.title}</strong>
                                            </div>
                                            <div className="quantity-controls">
                                                <button
                                                    onClick={() => handleQuantityChange(item.id, -1)}
                                                    disabled={item.quantity === 0}
                                                >
                                                    <RxBorderSolid className='icon-quantity' />
                                                </button>
                                                <span>{item.quantity}</span>
                                                <button onClick={() => handleQuantityChange(item.id, 1)}>
                                                    < HiPlusSmall className='icon-quantity' />
                                                </button>
                                            </div>
                                        </li>
                                    </>
                                ))}

                                <div>
                                    <p>Itens: {totalItems}</p>
                                    <p>Total: {totalValue} m³</p>
                                </div>
                            </ul>
                            <form className="contact-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input type="text" id="name" name="name" placeholder='Nome:' required />
                                </div>

                                <div className="form-group">
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        id="tel"
                                        type="tel"

                                        onChange={(e) => setTelefone(e.target.value)}
                                        placeholder='Telefone:'
                                        required
                                    />

                                </div>

                                <div className="form-group">
                                    <input type="email" id="email" name="email" placeholder='Email:' required />
                                </div>
                                <button type="submit" className="submit-button">Solicitar Orçamento</button>
                            </form>
                        </div>
                    </>
                )}

                {showPopup && <SuccessOrcamentoPopup onClose={handleClosePopup} />}
                {showPopupError && <ErrorPopup onClose={handleClosePopupError} />}
            </div>

        </div >
    );
};

export default Tabs;